import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import YouthBanner from "../components/youth-banner";

class YouthWorkingGroup extends Component {
    render() {
        return (
            <Layout headerElement={<YouthBanner />}>
                <SEO title="Justice Working Group" />
                <section id="section3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="img-box">
                                    <div className="img-box__img">
                                        <img alt="education" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/children.jpg" className="img--bg m-b-10" style={{height: '42%', marginTop: 125}} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <h2 className="text-medium" style={{marginBottom: 32}}><img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/nea-onnim-small.png" width="40" /> Youth</h2>
                                <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px'}}>
                                    <li>Violence</li>
                                    <li>Employment (lack of opportunity)</li>
                                    <li>Lack of access to common space (a place to meet, organize activities) </li>
                                    <li>Rural youth feeling isolated – leave to seek out opportunities outside communities</li>
                                </ul>
                                <div className="widget-title recommended" style={{textDecoration: 'underline'}}>Recommendations</div>
                                <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px', marginTop: 18}}>
                                    <li>Youth advocates/workers – to help youth navigate issues related to the justice and education systems</li>
                                    <li>Create safe spaces for our youth to go (i.e. rec centres)</li>
                                    <li>Create cultural and educational programs specifically for the rural areas</li>
                                </ul>
                                <div className="clear"></div>
                                <div className="seperator"><i className="fa fa-gavel"></i></div>
                            </div>
                        </div>

                    </div>
                </section>
                <div className="container">
                    <img src="https://demo.artureanec.com/html/helpo/img/bottom-bg.png" />
                </div>
            </Layout>
        );
    }
}

export default YouthWorkingGroup